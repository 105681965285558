import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store';
import { makeStyles } from '@mui/styles';
import { adaptV4Theme, createTheme, ThemeProvider } from '@mui/material/styles';
import { getNotificationsCount } from 'services/auth.service';

const rulePalette = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#ffffff',
      },
      secondary: {
        main: '#993300',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  }),
);

const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: '0 1px 8px rgba(0,0,0,.3)',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
  },
  toolBar: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
  },
  branding: {
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: 'auto 0',
    lineHeight: '50px',
    padding: '0 64px 0 0',
  },
  logo: {
    margin: 'auto',
    width: 150,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80px',
    },
  },
  searchWrapper: {
    flex: '1 1 0%',
    boxSizing: 'border-box',
  },
  profileWrapper: {
    flex: '1 1 0%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  searchForm: {
    background: 'white',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    display: 'block',
    maxWidth: '800px',
  },
  searchInput: {
    fontSize: '1rem',
    padding: theme.spacing(1) * 1.9,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
    cursor: 'text',
    textIndent: '30px',
    border: 'none',
    background: 'transparent',
    width: '100%',
    outline: '0',
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    left: '0',
    marginTop: '-24px',
    color: 'rgba(0,0,0,.87)',
  },
  marginRight: {
    marginRight: '16px !important',
  },
  userMenu: {
    top: '48px !important',
  },
}));

const Header = ({
  logo,
  logoAltText,
  toggleFullscreen,
  toggleDrawer,
  toggleNotifications,
  workspaceRoutes,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: authUser } = useSelector(x => x.auth);
  const { scope } = useSelector(x => x.auth);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [permissionAnchorEl, setPermissionAnchorEl] = useState(null);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  // const [scope, setScope] = useState('UserPerm');

  const allPermissions = [
    { id: 'UserPerm', label: 'Páciens' },
    { id: 'ProfessionalPerm', label: 'Terapeuta' },
    { id: 'AdminPerm', label: 'Admin' },
    { id: 'DevPerm', label: 'Fejlesztő' },
  ];

  useEffect(() => {
    getNotificationsCount().then(response => {
      if (!isNaN(parseInt(response.data.count))) {
        setNotificationsCount(response.data.count);
      }
    });
  }, []);

  const handleSettingsToggle = event => setAnchorEl(event.currentTarget);
  const handlePermissionsToggle = event => setPermissionAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);
  const handleClosePermissionMenu = () => setPermissionAnchorEl(null);

  const handleSearchExpandToggle = () => setSearchExpanded(!searchExpanded);

  const handleChangeScope = scope => {
    // setScope(scope);
    handleClosePermissionMenu();
    dispatch(authActions.changeScope(scope));
  };

  const permissions = allPermissions.filter(item => authUser?.user?.permissions?.includes(item.id));
  if (!scope && permissions.length) {
    dispatch(authActions.changeScope(permissions[0].id));
  }

  const profile = () => {
    handleCloseMenu();
    navigate('/profile');
  };

  const purpose = () => {
    handleCloseMenu();
    navigate('/purpose');
  };

  const logout = () => {
    dispatch(authActions.logout());
    navigate('/signin');
  };

  const handleDrawerToggle = () => {
    toggleDrawer();
    if (searchExpanded) handleSearchExpandToggle();
  };

  const handleNotificationToggle = () => {
    toggleNotifications();
    if (searchExpanded) handleSearchExpandToggle();
    setNotificationsCount(0);
  };

  return (
    <AppBar position='static' className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>

        <div className={classes.branding}>
          <img src={logo} alt={logoAltText} className={classes.logo} />
          <small>v.{process.env.REACT_APP_VERSION}</small>
        </div>

        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          {(workspaceRoutes?.quick ?? []).map((item, key) => (
            <Button
              key={key}
              variant='contained'
              color='default'
              startIcon={<item.icon />}
              href={item.path}
              className={classes.marginRight}
              sx={{ mr: 3 }}
            >
              {item.name}
            </Button>
          ))}
        </Box>

        <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
          <span className='flexSpacer' />
        </Box>

        {/* <Hidden xsDown>
          <div className={classes.searchWrapper}>
            <form className={classes.searchForm}>
              <IconButton aria-label='Search' className={classes.searchIcon}>
                <SearchIcon />
              </IconButton>
              <input
                className={classes.searchInput}
                type='text'
                placeholder='Search'
                autoFocus={true}
              />
            </form>
          </div>
        </Hidden>

        <Hidden smUp>
          <IconButton
            color='inherit'
            onClick={handleSearchExpandToggle}
            aria-expanded={searchExpanded}
            aria-label='Show searchbar'
          >
            <SearchIcon />
          </IconButton>
        </Hidden> */}

        {/* <Hidden xsDown>
          <IconButton color='inherit' onClick={toggleFullscreen}>
            <FullscreenIcon />
          </IconButton>
        </Hidden> */}

        {authUser && (
          <>
            <ThemeProvider theme={rulePalette}>
              <div className={classes.profileWrapper}>
                {authUser?.user?.permissions?.length > 1 && (
                  <Chip
                    variant='default'
                    color='primary'
                    label={
                      scope && permissions.length
                        ? permissions.find(item => item.id === scope).label
                        : ''
                    }
                    onClick={handlePermissionsToggle}
                    onDelete={handlePermissionsToggle}
                    deleteIcon={<KeyboardArrowDownIcon />}
                    className={classes.marginRight}
                  />
                )}
                <Chip
                  avatar={
                    authUser?.user?.picture ? (
                      <Avatar alt={authUser?.user?.firstName} src={authUser?.user?.picture} />
                    ) : (
                      <></>
                    )
                  }
                  variant='outlined'
                  color='primary'
                  label={authUser?.user?.firstName}
                />
              </div>
            </ThemeProvider>

            <IconButton color='inherit' onClick={handleNotificationToggle}>
              {notificationsCount > 0 ? (
                <Badge overlap='rectangular' badgeContent={notificationsCount} color='error'>
                  <NotificationsIcon />
                </Badge>
              ) : (
                <NotificationsIcon />
              )}
            </IconButton>

            <IconButton
              aria-label='User Settings'
              aria-owns={anchorEl ? 'user-menu' : null}
              aria-haspopup='true'
              color='inherit'
              onClick={handleSettingsToggle}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        )}

        <Menu
          id='permission-menu'
          anchorEl={permissionAnchorEl}
          open={Boolean(permissionAnchorEl)}
          onClose={handleClosePermissionMenu}
        >
          {permissions.map(permission => (
            <MenuItem
              key={permission.id}
              onClick={() => {
                handleChangeScope(permission.id);
              }}
            >
              <ListItemText primary={permission.label} />
            </MenuItem>
          ))}
        </Menu>

        <Menu
          id='user-menu'
          className={classes.userMenu}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={profile}>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary='Profil' />
          </MenuItem>
          <MenuItem onClick={purpose}>
            <ListItemIcon>
              <ViewStreamIcon />
            </ListItemIcon>
            <ListItemText primary='Használat célja' />
          </MenuItem>
          {/* <MenuItem onClick={handleCloseMenu}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary='Settings' />
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <ListItemIcon>
              <NotificationsOffIcon />
            </ListItemIcon>
            <ListItemText primary='Disable notifications' />
          </MenuItem> */}
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary='Kijelentkezés' />
          </MenuItem>
        </Menu>
      </Toolbar>
      {/* <Hidden smUp>
        <Collapse in={searchExpanded} timeout='auto' unmountOnExit>
          <Toolbar className={classes.toolBar}>
            <div className={classes.searchWrapper}>
              <form className={classNames(classes.searchForm, 'mr-0')}>
                <IconButton aria-label='Search' className={classes.searchIcon}>
                  <SearchIcon />
                </IconButton>
                <input
                  className={classes.searchInput}
                  type='text'
                  placeholder='Search'
                  autoFocus='true'
                />
              </form>
            </div>
          </Toolbar>
        </Collapse>
        </Hidden> */}
    </AppBar>
  );
};

Header.prototypes = {
  logo: PropTypes.string,
  logoAltText: PropTypes.string,
};

export default Header;
