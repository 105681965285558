import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { parseCondition } from './helper';
import { makeStyles } from '@mui/styles';
import { FormControl, FormHelperText, FormLabel, MenuItem, Select } from '@mui/material';

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
  formControl: {
    width: '100%',
    marginBottom: 16,
  },
  group: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
}));
const FormSelectFieldForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    triggerChange() {
      return handleChange(null);
    },
    elem() {
      return refElem.current;
    },
  }));

  const { attr, field, values, onChange, value, disabled } = props;
  const classes = useStyles();
  const selectFieldType = field.Type ?? 'single';
  const isRequired = field.isRequired;
  const requiredRegexp = field.RequiredRegexp;
  const condition = field.Condition ?? '';
  const description = field.Description ?? '';
  const styles = field.data?.styles ?? {};

  const [inputValue, setInputValue] = useState(selectFieldType === 'multiple' ? [] : '');
  const [errorMsg, setErrorMsg] = useState('');
  const refElem = useRef(null);

  useEffect(() => {
    let _value = value;
    if (selectFieldType === 'multiple' && _value && !Array.isArray(_value)) {
      _value = _value.split(',');
    }
    setInputValue(_value ? _value : selectFieldType === 'multiple' ? [] : '');
  }, [value]);

  const optionsData = field.SelectOptions?.selectOptions?.data ?? [];
  const options = [];
  for (const optionData of optionsData) {
    options.push({
      id: optionData.id,
      option: optionData.attributes?.Option,
    });
  }

  const handleChange = value => {
    let errMsg = '';
    if (disabled) {
      errMsg = 'Nem módosítható a mező értéke!';
      setErrorMsg(errMsg);
      return errMsg;
    }
    if (value !== null) {
      setInputValue(value);
    } else {
      value = inputValue;
    }
    if (isRequired && !value) {
      errMsg = 'Kötelező mező!';
    } else if (requiredRegexp && !value.match(new RegExp(requiredRegexp))) {
      errMsg = 'Nem megfelelő érték!';
    }
    setErrorMsg(errMsg);
    onChange(attr, value);
    return errMsg;
  };

  let input = <></>;
  console.log('select', inputValue);
  if (parseCondition(condition, values)) {
    input = (
      <FormControl
        component='fieldset'
        className={[classes.formControl, classes.group].join(' ')}
        style={styles?.FormControl ?? {}}
        required={isRequired}
        error={errorMsg ? true : false}
        // disabled={disabled}
        ref={refElem}
      >
        <FormLabel component='legend' style={styles?.FormLabel ?? {}}>
          {field.Name}
        </FormLabel>
        <Select
          variant={'outlined'}
          multiple={selectFieldType === 'multiple'}
          value={inputValue}
          onChange={event => handleChange(event.target.value)}
          style={styles?.Select ?? {}}
          // disabled={disabled}
          // renderValue={(selected) => selected.join(', ')}
        >
          {selectFieldType !== 'multiple' && (
            <MenuItem
              style={styles?.MenuItem ?? {}}
              style={styles?.MenuItemEmpty ?? {}}
              key={`${attr}[-1]`}
              value=''
            >
              &nbsp;
            </MenuItem>
          )}
          {options.map((option, index) => (
            <MenuItem
              style={styles?.MenuItem ?? {}}
              key={`${attr}[${index}]`}
              value={option.option}
            >
              {option.option.replace(/;/g, ',')}
            </MenuItem>
          ))}
        </Select>
        {description && (
          <FormHelperText style={styles?.FormHelperText ?? {}}>{description}</FormHelperText>
        )}
        {errorMsg && (
          <FormHelperText style={styles?.FormHelperText ?? {}} error={errorMsg ? true : false}>
            {errorMsg}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
  return input;
};
const FormSelectField = forwardRef(FormSelectFieldForwardRef);

FormSelectField.propTypes = {
  attr: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool.isRequired,
};

export default FormSelectField;
