import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { parseCondition } from './helper';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

const FormFieldCategoryForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    triggerChange() {
      // refInput.current.change();
    },
    elem() {
      return refElem.current;
    },
  }));

  const refElem = useRef(null);

  const { field, values } = props;
  const styles = field.data?.styles ?? {};

  const classes = useStyles();
  const condition = field.Condition ?? '';
  if (parseCondition(condition, values)) {
    return (
      <Typography
        className={classes.width100}
        style={styles?.Typography ?? {}}
        variant={'h3'}
        ref={refElem}
      >
        {field.Name}
      </Typography>
    );
  }
  return <></>;
};
const FormFieldCategory = forwardRef(FormFieldCategoryForwardRef);

FormFieldCategory.propTypes = {
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default FormFieldCategory;
