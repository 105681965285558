import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { parseCondition } from './helper';
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import ImageUploading from 'react-images-uploading';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
  formControl: {
    width: '100%',
    marginBottom: 16,
  },
}));

const FormFileFieldForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    triggerChange() {
      return handleChange(null);
    },
    elem() {
      return refElem.current;
    },
  }));

  const { attr, field, values, onChange, value, disabled } = props;

  const classes = useStyles();

  const refElem = useRef(null);

  const fileFieldType = field.Type ?? 'single';
  const fileAcceptable = field.Acceptable ?? '';
  const isRequired = field.isRequired;
  const requiredRegexp = field.RequiredRegexp;
  const condition = field.Condition ?? '';
  const description = field.Description ?? '';
  const styles = field.data?.styles ?? {};

  const [inputValue, setInputValue] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const maxNumber = 5;

  useEffect(() => {
    console.log('filefield', value);
    let _inputValue = value;
    if (Array.isArray(_inputValue)) {
      setInputValue(_inputValue);
    } else {
      setInputValue(value ? value.split(',') : []);
    }
  }, [value]);

  const onFileChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    // setInputValue(imageList ?? []);
    handleChange(imageList ?? []);
    // handleChange(null);
  };

  // useEffect(() => {
  //   handleChange(null);
  // }, [inputValue]);

  const handleChange = value => {
    console.log('handleChange');
    if (value !== null) {
      setInputValue(value);
    } else {
      const fileValues = [];
      for (const fileValue of inputValue ?? []) {
        console.log('fileValue', fileValue);
        if (typeof fileValue === 'string' && fileValue) {
          fileValues.push({
            src: fileValue,
          });
        } else if (fileValue.file && fileValue.data_url) {
          fileValues.push({
            name: fileValue.file.name,
            size: fileValue.file.size,
            type: fileValue.file.type,
            dataUrl: fileValue.data_url,
          });
        }
      }
      value = fileValues;
    }
    let errMsg = '';
    if (isRequired && !value) {
      errMsg = 'Kötelező mező!';
    } else if (requiredRegexp && !value.match(new RegExp(requiredRegexp))) {
      errMsg = 'Nem megfelelő érték!';
    }
    setErrorMsg(errMsg);
    onChange(attr, value);
    return errMsg;
  };

  const openFile = content => {
    let pdfWindow = window.open('');
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='" + encodeURI(content) + "'></iframe>",
    );
  };

  if (parseCondition(condition, values)) {
    return (
      <FormControl
        component='fieldset'
        error={errorMsg ? true : false}
        className={classes.formControl}
        style={styles?.FormControl ?? {}}
        disabled={disabled}
        ref={refElem}
      >
        <FormLabel component='legend' style={styles?.FormLabel ?? {}}>
          {field.Name}
        </FormLabel>
        <ImageUploading
          multiple={fileFieldType === 'multiple'}
          value={inputValue}
          allowNonImageType={true}
          acceptType={fileAcceptable ? fileAcceptable.split(',') : []}
          onChange={onFileChange}
          maxNumber={fileFieldType === 'multiple' ? maxNumber : 1}
          dataURLKey='data_url'
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div {...dragProps} style={styles?.div ?? {}}>
              <Grid container direction={'column'}>
                <Grid item>
                  {(fileFieldType === 'multiple' || !imageList.length) && (
                    <Button
                      onClick={onImageUpload}
                      color={isDragging ? 'secondary' : 'primary'}
                      variant='contained'
                      size='large'
                      startIcon={<CloudUploadIcon />}
                      disabled={disabled}
                    >
                      File feltöltés
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <List>
                    {Array.isArray(imageList) &&
                      imageList.length > 0 &&
                      imageList.map((file, index) => {
                        let listItem = <></>;
                        if (file?.file) {
                          if (file.data_url) {
                            listItem = (
                              <Link
                                to={'#'}
                                onClick={() => {
                                  openFile(file.data_url);
                                }}
                              >
                                {file.file.name}
                              </Link>
                            );
                          } else {
                            listItem = <>{file.file.name}</>;
                          }
                        } else if (
                          typeof file === 'string' &&
                          file.match(/^\/user\/[\d]+\/[\d]+\//)
                        ) {
                          listItem = (
                            <Link
                              to={`${process.env.REACT_APP_API_ENDPOINT}${file.substring(1)}`}
                              target={'_blank'}
                            >
                              {file.split('/')?.pop() ?? ''}
                            </Link>
                          );
                        }
                        return (
                          <ListItem key={index}>
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={listItem} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge='end'
                                aria-label='delete'
                                onClick={() => onImageRemove(index)}
                                disabled={disabled}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                  </List>
                </Grid>
              </Grid>
            </div>
          )}
        </ImageUploading>
        {description && (
          <FormHelperText style={styles?.FormHelperText ?? {}}>{description}</FormHelperText>
        )}
        {errorMsg && (
          <FormHelperText style={styles?.FormHelperText ?? {}} error={errorMsg ? true : false}>
            {errorMsg}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
  return <></>;
};
const FormFileField = forwardRef(FormFileFieldForwardRef);
FormFileField.propTypes = {
  attr: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool.isRequired,
};
export default FormFileField;
