import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { parseCondition } from './helper';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
  formControl: {
    width: '100%',
    marginBottom: 16,
  },
}));

const FormFieldForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    triggerChange() {
      return handleChange(null);
    },
    elem() {
      return refElem.current;
    },
  }));

  const { attr, field, values, onChange, value, disabled } = props;

  const refElem = useRef(null);
  const refTimer = useRef(null);

  const classes = useStyles();

  const textFieldType = field.Type ?? 'text';
  const isRequired = field.isRequired;
  const requiredRegexp = field.RequiredRegexp;
  const condition = field.Condition ?? '';
  const description = field.Description ?? '';
  const styles = field.data?.styles ?? {};

  const [inputValue, setInputValue] = useState(
    textFieldType === 'float' || textFieldType === 'number' ? 0 : '',
  );
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  const handleChange = value => {
    if (value !== null) {
      setInputValue(value);
    } else {
      value = inputValue;
    }
    let errMsg = '';
    if (isRequired && !value) {
      errMsg = 'Kötelező mező!';
    } else if (requiredRegexp && !value.match(new RegExp(requiredRegexp))) {
      errMsg = 'Nem megfelelő érték!';
    }
    setErrorMsg(errMsg);
    if (refTimer.current) {
      clearTimeout(refTimer.current);
    }
    refTimer.current = setTimeout(() => {
      onChange(attr, value);
    }, 300);
    return errMsg;
  };

  if (parseCondition(condition, values)) {
    return (
      <FormControl
        component='fieldset'
        className={classes.formControl}
        style={styles?.FormControl ?? {}}
        disabled={disabled}
        ref={refElem}
      >
        <FormLabel component='legend' style={styles?.FormLabel ?? {}}>
          {field.Name}
        </FormLabel>
        <TextField
          variant={'outlined'}
          className={classes.width100}
          style={styles?.TextField ?? {}}
          margin={'dense'}
          type={textFieldType === 'float' ? 'number' : textFieldType}
          multiline={textFieldType === 'textarea'}
          minrows={textFieldType === 'textarea' ? 4 : 1}
          maxRows={textFieldType === 'textarea' ? 8 : 1}
          name={attr}
          required={isRequired}
          error={errorMsg ? true : false}
          helperText={errorMsg}
          value={inputValue}
          onChange={event => handleChange(event.target.value)}
          disabled={disabled}
          onFocus={event => {
            event.target.select();
          }}
        />
        {description && (
          <FormHelperText style={styles?.FormHelperText ?? {}}>{description}</FormHelperText>
        )}
      </FormControl>
    );
  }
  return <></>;
};
const FormField = forwardRef(FormFieldForwardRef);
FormField.propTypes = {
  attr: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool.isRequired,
};
export default FormField;
